
















































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Popover from '@/components/Popover.vue';
import { ActionButton, ActionButtonList } from '@/components/actionbuttons';
import Quill from '@/components/Quill.vue';
import { SmsText } from '@/models';
import { SmsService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';
import { QuillOptionsStatic } from 'quill';

const QUILL_CONFIG: QuillOptionsStatic = {
  modules: {
    smscounter: {
      container: '#smscounter',
    },
  },
};

const QUILL_CONTAINERS = [
  [
    {
      tags: [
        'company-name',
        'company-contact',
        'sender',
        'invoice-number',
        'invoice-date',
        'invoice-due-date',
        'invoice-amount',
        'current-month',
        'previous-month',
        'next-month',
        'current-year',
        'previous-year',
        'next-year',
        'payment-link',
        'view-invoice',
        'accept-button',
        'quotation-number',
      ],
    },
  ],
];

@Component({
  components: {
    Popover,
    ActionButton,
    ActionButtonList,
    Quill,
  },
})
export default class smsMessageEditor extends Vue {
  loading: boolean = true;
  item: SmsText = new SmsText();
  editItem: SmsText = new SmsText();
  error: string = '';
  editingTitle: boolean = false;
  newText: boolean = false;

  private quillContainer = QUILL_CONTAINERS;
  private quillConfig = QUILL_CONFIG;

  async mounted(): Promise<void> {
    try {
      const id = this.$route.params['id'];
      if (id === 'new') {
        this.editingTitle = true;
        this.newText = true;
      } else if (+id > 0) {
        this.item = await SmsService.getText(+id);
      } else {
        this.error = 'Invalid parameter given';
        return;
      }

      Object.assign(this.editItem, this.item);
      this.loading = false;
    } catch (e) {
      this.$toaster.error('Could not load sms', unwrapError(e));
    }
  }

  async saveBody(): Promise<void> {
    try {
      if (this.newText) {
        this.item = await SmsService.createText(this.editItem);
        this.editingTitle = false;
        this.newText = false;
      } else {
        this.item.text = this.editItem.text;
        this.item = await SmsService.updateText(this.item);
      }
      this.$toaster.success('Sms text saved');
      this.$router.push('/settings/sms/');
    } catch (e) {
      this.$toaster.error('Sms editing failed', unwrapError(e));
    }
  }

  editTitle(): void {
    this.editItem.name = this.item.name;
    this.editingTitle = true;
  }

  async saveTitle(): Promise<void> {
    try {
      this.item.name = this.editItem.name;
      this.item = await SmsService.updateText(this.item);
      this.editingTitle = false;
      this.$toaster.success('Name edited');
    } catch (e) {
      this.$toaster.error('Name editing failed', unwrapError(e));
    }
  }

  async deleteText(): Promise<void> {
    try {
      await SmsService.deleteText(this.item.id);
      this.$toaster.success('Deleted');
      this.close();
    } catch (e) {
      this.$toaster.error('Error deleting', unwrapError(e));
    }
  }

  close(): void {
    this.$router.push({ name: 'settings-sms' });
  }
}
